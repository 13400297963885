export default {
  DOWNLOAD_1095B_CTA: 'download-1095b',
  APPOINT_A_REP_LANDING_CONTENT: 'appoint-a-rep-landing-content',
  ASK_VA: 'ask-va-widget',
  BANNER: 'banner',
  BASIC_FACILITY_LOCATIONS_LIST: 'basic-facility-locations-list',
  BURIAL_HOW_DO_I_APPLY_WIDGET: 'burial-how-do-i-apply-widget',
  BTSSS_LOGIN: 'btsss-login',
  CHAPTER_31_CTA: 'chapter-31-cta',
  CHAPTER_31_WIZARD: 'chapter-31-Wizard',
  CHAPTER_36_CTA: 'chapter-36-cta',
  COE_ACCESS: 'coe_access',
  COVID_VACCINE_UPDATES_CTA: 'va-covid-vaccine-updates-cta',
  CTA: 'cta',
  DEPENDENCY_VERIFICATION: 'dependency-verification',
  DISABILITY_APP_STATUS: 'disability-app-status',
  DISABILITY_RATING_CALCULATOR: 'disability-rating-calculator',
  EDUCATION_APP_STATUS: 'education-app-status',
  EVENTS: 'events',
  EZR_SUBMISSION_OPTIONS: 'ezr-submission-options',
  FACILITY_APPOINTMENT_WAIT_TIMES_WIDGET:
    'facility-appointment-wait-times-widget',
  FACILITY_DETAIL: 'facility-detail',
  FACILITY_DETAIL_SATELLITE_LOCATIONS: 'facility-detail-satellite-locations',
  FACILITY_LOCATIONS_LIST: 'facility-locations-list',
  FACILITY_MAP: 'facility-map',
  FACILITY_MAP_SATELLITE_MAIN_OFFICE: 'facility-map-satellite-main-office',
  FACILITY_MAP_SATELLITE_LOCATIONS: 'facility-map-satellite-locations',
  FACILITY_PATIENT_SATISFACTION_SCORES: 'facility-patient-satisfaction-scores',
  FACILITY_PATIENT_SATISFACTION_SCORES_SATELLITE_LOCATIONS:
    'facility-patient-satisfaction-scores-satellite-locations',
  FIND_A_REP_LANDING_CONTENT: 'find-a-rep-landing-content',
  FIND_VA_FORMS: 'find-va-forms',
  FIND_VA_FORMS_DOWNLOAD_MODAL: 'find-va-forms-pdf-download-helper',
  FORM_10182_CTA: 'form-10182-cta',
  FORM_686_CTA: 'form-686-CTA',
  FORM_2010206_CTA: 'form2010206',
  FORM_2010207_CTA: 'form2010207',
  FORM_210845_CTA: 'form210845',
  FORM_210966_CTA: 'form210966',
  FORM_210972_CTA: 'form210972',
  FORM_2110210_CTA: 'form2110210',
  FORM_214138_CTA: 'form214138',
  FORM_214142_CTA: 'form214142',
  FORM_21P0847_CTA: 'form21p-0847',
  FORM_264555_CTA: 'form264555',
  FORM_400247_CTA: 'form400247',
  FORM_UPLOAD: 'form-upload',
  FORM_1010D: 'form1010d',
  FORM_107959C: 'form107959c',
  FORM_107959A: 'form107959a',
  FORM_107959F2: 'form107959f2',
  GET_MEDICAL_RECORDS_PAGE: 'get-medical-records-page',
  HCA_PERFORMANCE_WARNING: 'hca-performance-warning',
  HEALTH_CARE_APP_STATUS: 'health-care-app-status',
  HIGHER_LEVEL_REVIEW_APP_STATUS: 'higher-level-review-status',
  HOMEPAGE_EMAIL_SIGNUP: 'homepage-email-signup',
  HOMEPAGE_HERO_RANDOMIZER: 'homepage-hero-randomizer',
  HOMEPAGE_SEARCH: 'homepage-search',
  I_18_SELECT: 'i18-select',
  MAINTENANCE_BANNER: 'maintenance-banner',
  MANAGE_VA_DEBT_CTA: 'manage-va-debt-cta',
  MEDICAL_COPAYS_CTA: 'medical-copays-cta',
  MHV_SIGNIN_CTA: 'mhv-signin-cta',
  OPT_OUT_APP_STATUS: 'opt-out-app-status',
  OTHER_FACILITY_LOCATIONS_LIST: 'other-facility-locations-list',
  PENSION_APP_STATUS: 'pension-app-status',
  PENSION_HOW_DO_I_APPLY_WIDGET: 'pension-how-do-i-apply-widget',
  POST_911_GI_BILL_STATUS_WIDGET: 'post-9-11-gi-bill-status',
  REFILL_TRACK_PRESCRIPTIONS_PAGE: 'refill-track-prescriptions-page',
  REPRESENTATIVE_STATUS: 'representative-status',
  RESOURCES_AND_SUPPORT_SEARCH: 'resources-and-support-search',
  SCHEDULE_VIEW_VA_APPOINTMENTS_PAGE: 'schedule-view-va-appointments-page',
  SCO_ANNOUNCEMENTS: 'sco-announcements',
  SCO_EVENTS: 'sco-events',
  SECURE_MESSAGING_PAGE: 'secure-messaging-page',
  SIDE_NAV: 'side-nav',
  SITUATION_UPDATES_BANNER: 'situation-updates-banner',
  SUPPLEMENTAL_CLAIM: 'supplemental_claim',
  THIRD_PARTY_APP_DIRECTORY: 'third-party-app-directory',
  VET_CENTER_HOURS: 'vet-center-hours',
  VET_CENTER_HOURS_SATELLITE_LOCATIONS: 'vet-center-hours-satellite-locations',
  VET_CENTER_NEARBY: 'vet-center-nearby',
  VA_LOCATION_NEARBY: 'va-location-nearby',
  VIEW_DEPENDENTS_CTA: 'view-dependents-CTA',
  VIEW_PAYMENT_HISTORY: 'view-payment-history',
  VIEW_TEST_AND_LAB_RESULTS_PAGE: 'view-test-and-lab-results-page',
  VIEW_ENROLLMENT_VERIFICATION_LOGIN: 'view-enrollment-verification-login',
  VIEW_EDUCATION_LETTERS_LOGIN: 'view-education-letters-login',
  VYE_ENROLLMENT_LOGIN_WIDGET: 'vye-enrollment-login-widget',
};
